<template>
    <div class="image">
        <div class="bg-primary py-3 fl-x-cc logo-contain" style="width: 100%;">
            <img class="logo" src="../../assets/web/logos/Bank-e-Auctions-logo.png" alt="logo">
        </div>
        <div class="container mt-4">
            <div class="w-55r fl-eq bs-3 m-auto min-h-70 row">
                <div class="auth-side-bg pos-r col-12 col-lg-6">
                    <div class="overlay-blue"></div>
                    <section class="side-heading pos-r">
                        <div class="square-secondary-abs"></div>
                        <div class="bullet-right-abs d-sm-none"></div>
                        <slot name="heading"></slot>
                    </section>
                </div>
                <div class="p-5 bg-2 col-12 col-lg-6" style="width: 50%;">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'LoginLayout'
};
</script>

<style scoped lang="scss">
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.auth-side-bg {
    width: 50%;
    background-size: cover;
    background: url("../../assets/web/auth/login_img_mobile.png");
    @media (min-width: 992px) {
        background: url("../../assets/web/auth/login-web.jpg");
    }
}

.side-heading {
    //left: 40%;
    padding: var(--spacer-4);
    z-index: 4 !important;
    @media (min-width: 992px) {
        padding:0 !important;
        position: absolute;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%);
        z-index: 4 !important;
    }

    h2 {
        font-size: 1.8rem !important;
    }
}

.overlay-blue {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 76, 145, 0.4);
    //z-index: -1;
}

/*.image {*/
/*    background: url("../../assets/web/homee/banner.png");*/
/*}*/

.link {
    cursor: pointer;
}

.logo-contain {

    .logo {
        display: block !important;
        padding-left: 0 !important;
        height: 3.5rem !important;
    }

}
</style>
