<template>
    <login-layout>
        <template #heading>
            <div class="pos-r">
                <div>
                    <h2 class="text-white">Login to</h2>
                    <p class="font-inter-regular text-white fs-lg-7 m-0"> Admin Portal</p>
                </div>
            </div>
        </template>
        <template #default>
            <div @click="errorMessage=''" class="mt-4">
                <div class="text-center">
                    <h5 class="fs-lg-6 mb-1 font-inter-semi-bold">Verify Your Mobile Number</h5>
                    <p class="font-inter-medium fs--1 ml-2 text-5 text-left">
                        An OTP has been sent to your registered mobile <br>
                        number +91 {{ mobile }} <br> <br>
                        <span class="mt-2">Please enter the OTP below to verify your number</span>
                    </p>
                </div>
                <s-form @submit="VerifyOTP">
                    <validated-input label="Enter the OTP" placeholder="" type="password"
                                     v-model="model.otp" class="text-secondary mb-1 c-form-input"
                                     :disabled="loading" :rules="rules.password"/>
                    <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
                    <p class="mb-4 text-right font-inter-medium text-5 link fs--1">
                        Not yet received ?
                        <span class="text-secondary ml-2" @click="OTPResend">Resend</span>
                    </p>
                    <div class="bgc-success round-1" v-if="resendMessage">
                        <p class="text-center text-white">{{ resendMessage }}</p>
                    </div>
                    <div class="text-center">
                        <btn class="pl-5 pr-5 font-inter-medium" block text="Login" loading-text="Validating..." size=""
                             :disabled="loading" :loading="loading"/>
                    </div>
                </s-form>
            </div>
        </template>
    </login-layout>
</template>

<script>
import LoginLayout from '@/views/auth/LoginPageLayout';
import urls from '@/data/urls';
import axios from 'secure-axios';
import { mapActions } from 'vuex';

export default {
    name : 'VerifyMobilePage',

    components : { LoginLayout },

    props : {
        mobile : { type : String },

        loginModel : {
            type : Object
        }
    },

    data () {
        return {
            loading       : false,
            VerifyUrl     : urls.auth.verify,
            errorMessage  : '',
            resend        : false,
            resendMessage : '',

            model : {
                otp : ''
            },

            rules : {
                email : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    },

    methods : {
        ...mapActions(['setUser']),

        async VerifyOTP () {
            const that = this;
            that.loading = true;
            const model = {
                ...that.model,
                ...that.loginModel
            };
            const response = await axios.form(this.VerifyUrl, model, ['username', 'password', 'otp']);
            const json = response.data;
            if (json.otp_success) {
                if (json.user.is_superuser) {
                    that.setUser(json.user);
                    await that.$router.push({ path : '/' }).then(() => {
                        window.location.reload(true);
                    });
                    that.loading = false;
                } else if (json.user.is_ventura_admin) {
                    that.setUser(json.user);
                    await that.$router.push({ path : '/' }).then(() => {
                        window.location.reload(true);
                    });
                    that.loading = false;
                } else if (json.user.is_bank_admin) {
                    that.setUser(json.user);
                    await that.$router.push({ path : '/' }).then(() => {
                        window.location.reload(true);
                    });
                    that.loading = false;
                } else if (json.user.is_bank_user) {
                    that.setUser(json.user);
                    await that.$router.push({ path : '/' }).then(() => {
                        window.location.reload(true);
                    });
                    that.loading = false;
                } else {
                    that.setUser(json.user);
                    await that.$router.push({ path : '/' }).then(() => {
                        window.location.reload(true);
                    });
                    that.loading = false;
                }
            } else {
                that.errorMessage = json.message;
                that.loading = false;
            }
            that.loading = false;
            localStorage.clear();
        },

        async OTPResend () {
            this.resend = true;
            this.resendMessage = 'Sending OTP...';
            const model = {
                ...this.loginModel
            };
            const response = await axios.form(urls.auth.resend, model, ['username', 'password']);
            const json = response.data;
            if (json.user) {
                this.showOTPPage = true;
                this.resendMessage = json.message;
            } else {
                this.errorMessage = 'Something went wrong Please try again...!';
            }
        }

    }
};
</script>

<style scoped>

</style>
