<template>
    <div>
        <login-layout v-if="!showOTPPage">

            <template #heading>
                <div class="pos-r">
                    <div>
                        <h2 class="text-white">Login to </h2>
                        <p class="font-inter-extra-light text-white fs-lg-7 m-0">Admin Portal</p>
                    </div>
                </div>
            </template>

            <template #default>
                <div @click="errorMessage=''" class="mt-4 px-lg-4">

                    <div class="text-center">
                        <h5 class="fs-lg-4 font-inter-semi-bold mb-1">Welcome to <br> Bank e Auctions India.com</h5>
                        <p class="font-inter-light fs--2">Please enter the details below to login</p>
                    </div>

                    <s-form @submit="loginClicked">

                        <validated-input label="Username" placeholder="Enter your username" name="Username" type="text"
                                         class="mb-3 text-secondary min-h-5re c-login-input"
                                         v-model="model.username"
                                         :disabled="loading" :rules="rules.username"/>

                        <validated-input label="Password" placeholder="Enter Password" type="password"
                                         v-model="model.password" class="text-secondary mb-1 min-h-5re c-login-input"
                                         :disabled="loading" :rules="rules.password"/>

                        <span class="text-danger fs--1" v-if="errorMessage" v-html="errorMessage"></span>

                        <p @click="$router.push('/forgot-password/')"
                           class="mb-4 text-right font-inter-medium link text-secondary fs--1">Forgot Password ?</p>

                        <div class="text-center">
                            <btn class="pl-5 pr-5 font-inter-medium" block text="Login" loading-text="Validating..."
                                 size=""
                                 :disabled="loading" :loading="loading"/>
                        </div>

                    </s-form>
                </div>
            </template>

        </login-layout>

        <verify-mobile-page v-else :login-model="model" :mobile="mobileNumber"/>
    </div>

</template>

<script>
import urls from '@/data/urls';
import LoginLayout from './LoginPageLayout';
import axios from 'secure-axios';
import VerifyMobilePage from './VerifyMobilePage';

export default {
    name : 'Login',

    components : { VerifyMobilePage, LoginLayout },

    data () {
        return {
            showOTPPage : false,
            loading     : false,
            loginUrl    : urls.auth.login,

            errorMessage : '',
            mobileNumber : '',

            model : {
                username : '',
                password : ''
            },

            rules : {
                username : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    },

    beforeMount () {
        this.loadData();
    },

    methods : {
        async loadData () {
            const response = await axios.get(urls.auth.status);
            console.log('response : ', response.data);
            if (response.data['public-key']) {
            } else {
            }
        },

        async loginClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model, ['username', 'password']);
            const json = response.data;
            console.log('json', json);
            if (json.user) {
                this.showOTPPage = true;
                this.mobileNumber = json.mobile;
            } else {
                that.errorMessage = json.errors.__all__ || 'Invalid Credentials';
                that.loading = false;
            }
            that.loading = false;
            localStorage.clear();
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

/*.image {*/
/*    background: url("../../assets/web/homee/banner.png");*/
/*}*/

.link {
    cursor: pointer;
}
.min-h-5re{
    min-height: 5.4rem!important;
}
</style>
